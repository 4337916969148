<!-- Mto particular de Usuarios -->

<template>
  <div class="usuM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">

          <div style="width:1040px">
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor">

                <!-- Botoneras -->
                <div class="pt-2 pl-2 d-flex">

                  <!-- Mto -->
                  <baseBtraMto
                    v-if="!readOnly"
                    :perm="permMto"
                    :modulo="btMtoCfg"
                    :estado="estado"
                    @onEvent="execAccion">
                  </baseBtraMto>

                  <baseBtraExtra
                    style="margin-left:50px"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>

                </div>


                <!-- Controles del Mto -->
                <div class="conflex pl-2" style="margin-top:10px">
                  <div class="columna" style="width:50%">
                    <div class="conflex" style="justify-content:space-between">
                      <div class="cab ">IDENTIFICACIÓN</div>
                      <div style="color:steelblue;padding-right:20px">
                        {{ ct.id[2] }}
                      </div>
                    </div>
                    <v-sheet width="480px" v-bind="$cfg.styles.marco">
                      <div class="conflex">
                        <!-- <div>
                          <v-switch
                            style="flex:1 1 15%;margin:0;padding:0"
                            true-value="1"
                            false-value="0"
                            color="green"
                            :readonly="noEdit"
                            v-model="ct.activo[2]">
                          </v-switch>

                          <div style="margin-top:-10px;margin-right:10px">
                            Activo
                          </div>
                        </div> -->
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:60%"
                          v-model="ct.usu[2]"
                          :label="ct.usu[1]"
                          :disabled="noEdit">
                        </uiText>
                        <div style="width:40%">
                          <v-switch
                            style="flex:1 1 15%;margin:0;padding:0"
                            true-value="1"
                            false-value="0"
                            color="green"
                            :readonly="noEdit"
                            v-model="ct.activo[2]">
                          </v-switch>
                          <div style="margin-top:-10px;margin-right:10px">Activo</div>
                        </div>
                      </div>

                      <uiText
                        styleInput="font-weight: bold;"
                        v-model="ct.name[2]"
                        :label="ct.name[1]"
                        :disabled="noEdit">
                      </uiText>

                      <div style="display:flex">
                        <v-select
                          style="flex: 1 1 80%"
                          v-bind="$select"
                          v-model="ct.dep[2]"
                          :label="ct.dep[1]"
                          :items="itemsDeptos"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name">
                        </v-select>

                        <v-select
                          style="flex: 1 1 20%"
                          v-bind="$select"
                          v-model="ct.nivel[2]"
                          :label="ct.nivel[1]"
                          :items="itemsNiveles"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name">
                        </v-select>
                      </div>
                    </v-sheet>

                    <div style="display:flex;width:100%">

                      <div class="columna">
                        <div class="cab" style="width:140px">FOTO</div>
                        <v-sheet v-bind="$cfg.styles.marco">
                          <uiImg
                            max-height="200"
                            width="150"
                            :ct="ct.foto"
                            @change="changeImg"
                            :disabled="estado!=='nuevo'">
                          </uiImg>
                        </v-sheet>
                      </div>

                      <div class="columna">
                        <div class="cab" style="width:310px">OBSERVACIONES</div>
                        <v-sheet v-bind="$cfg.styles.marco">
                          <uiObs3
                            :record="record"
                            :schema="ct"
                            :schemaComponente="ct.obs"
                            :edicion="!noEdit">
                          </uiObs3>
                        </v-sheet>
                      </div>
                    </div>
                  </div>


                  <div class="columna" style="width:50%" >
                    <div class="cab">DATOS GENERALES</div>
                    <v-sheet width="480px" v-bind="$cfg.styles.marco">
                      <uiText
                        v-model="ct.nif[2]"
                        :label="ct.nif[1]"
                        :disabled="noEdit">
                      </uiText>

                      <div style="display:flex">
                        <uiText
                          style="width:75%"
                          v-model="ct.dir[2]"
                          :label="ct.dir[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:25%"
                          v-model="ct.cpo[2]"
                          :label="ct.cpo[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:75%"
                          v-model="ct.pob[2]"
                          :label="ct.pob[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:25%"
                          v-model="ct.pro[2]"
                          :label="ct.pro[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:25%"
                          v-model="ct.tfn1[2]"
                          :label="ct.tfn1[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:25%"
                          v-model="ct.tfn2[2]"
                          :label="ct.tfn2[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:25%"
                          v-model="ct.mov1[2]"
                          :label="ct.mov1[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:25%"
                          v-model="ct.mov2[2]"
                          :label="ct.mov2[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          v-model="ct.email[2]"
                          :label="ct.email[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          v-model="ct.emailp[2]"
                          :label="ct.emailp[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>
                    </v-sheet>

                    <div class="cab">OTROS DATOS</div>
                    <v-sheet width="480px" v-bind="$cfg.styles.marco">
                      <div style="display:flex">
                        <uiDate
                          v-model="ct.fhnac[2]"
                          :label="ct.fhnac[1]"
                          :disabled="noEdit">
                        </uiDate>

                        <uiDate
                          v-model="ct.fhalta[2]"
                          :label="ct.fhalta[1]"
                          :disabled="noEdit">
                        </uiDate>

                        <uiDate
                          v-model="ct.fhbaja[2]"
                          :label="ct.fhbaja[1]"
                          :disabled="noEdit">
                        </uiDate>

                      </div>

                      <div style="display:flex">
                        <uiPass
                          :ct="ct.pass"
                          :disabled="noEdit">
                        </uiPass>

                        <uiDate
                          v-model="ct.acceso[2]"
                          :label="ct.acceso[1]"
                          type="datetime-local"
                          :disabled="true">
                        </uiDate>

                        <uiDate
                          v-model="ct.fhpass[2]"
                          :label="ct.fhpass[1]"
                          type="datetime-local"
                          :disabled="true">
                        </uiDate>

                      </div>

                      <uiText
                        v-model="ct.catpro[2]"
                        :label="ct.catpro[1]"
                        :disabled="noEdit">
                      </uiText>

                      <v-textarea
                          v-bind="$textarea"
                          v-model="ct.estudios[2]"
                          :label="ct.estudios[1]"
                          rows="3"
                          no-resize>
                      </v-textarea>

                      <!-- <div style="display:flex">
                        <v-checkbox
                            v-bind="$checkbox"
                            v-model="ct.aviso1[2]"
                            :label="ct.aviso1[1]"
                            :disabled="noEdit">
                        </v-checkbox>

                        <v-checkbox
                            v-bind="$checkbox"
                            v-model="ct.aviso2[2]"
                            :label="ct.aviso2[1]"
                            :disabled="noEdit">
                        </v-checkbox>

                        <v-checkbox
                            v-bind="$checkbox"
                            v-model="ct.aviso3[2]"
                            :label="ct.aviso3[1]"
                            :disabled="noEdit">
                        </v-checkbox>
                      </div> -->
                    </v-sheet>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>


    <!-- Ventana de Componentes Dinámicos  -->
    <component
      :is="componenteDinamico"
      syncUpdate
      :storeRaiz="storeName"
      :masterStore="storeName"
      :readOnly="readOnly"
      tipoDocumento='1'
      @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
    </component>

  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
  import commonDocsF from "@/components/commonDocsF.vue";

  export default {
    mixins: [mixM],
    components: {
      baseHeader,
      baseBtraMto,
      baseBtraExtra,
      dualTemplate,
      commonDocsF
    },

    props: {},

    data() {
      return {
        stIni: {
          api: "usuM",
          name:"usuM",
          titulo:"· USUARIOS",
          recordAccess:"local",
          mView:'usuM',
          pView:[]
        },

        itemsDeptos: [],
        itemsNiveles: []
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion: 'documentos', btn: "docs" },
        ];

        // departamentos
        this.itemsDeptos= this.$store.state.datos_iniciales.deptos;

        // niveles
        for (let x=1; x<10; x++) {
          this.itemsNiveles.push({ id:String(x), name:x});
        }
      },


      //
      iniDataApi() {
        this.apiArgs.imagenSet= ["", "imagenDB", { fnt:'set', tip:0, tip_id:0 }, "aplica"];
        this.apiArgs.imagenDel= ["", "imagenDB", { fnt:'del', tip:0, tip_id:0 }, "aplica"];
      },


      // entra al cambiar la imagen de la foto
      // guarda imagen
      changeImg() {
        this.guardarImagen();
      },

      // guarda / elimina imagen
      async guardarImagen() {
        let api= this.ct.foto[4].comp.files=== -1? 'imagenDel' : 'imagenSet';

        // apiCall
        let args= await this.$store.dispatch("apiCallComposicion",
          { apiArg: this.apiArgs[api], args:{ tip:this.ct.foto[4].comp.tip, tip_id:this.record.id }});
        args.args= JSON.stringify(args.args);

        // pasa los argumentos a FORMDATA
        let formData = new FormData();
        for (const key in args) {
          formData.append(key, args[key]);
        }

        // añade la imagen a formData
        formData.append("DOCUM", this.ct.foto[4].comp.files);
        formData.append("token", sessionStorage.getItem("login") || null);

        // ejecuto llamada API
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: formData });
        if (apiResult.sql.error)
          this.$root.$alert.open(apiResult.sql.msg, 'error');
        else
          this.$root.$alert.open('Imagen ' + (api=='imagenDel'? 'ELIMINADA':'GUARDADA') + ' correctamente !!', 'success', 1500);

      },


      // COMPONENTES DINAMICOS (asignación)
      //
      documentos() {
        //alert('docs');
        this.componenteDinamico= 'commonDocsF';
      },


    }
  };
</script>
